import AenderungsmodusPatch from './AenderungsmodusPatch';
import AenderungsmodusSection from './AenderungsmodusSection';

export default class AenderungsmodusHistory {
  /**
   * @param {String[]} order
   * @param {Object} sections
   */
  constructor(
    order = [],
    sections = {}
  ) {
    this.order = order;
    const mappedSections = AenderungsmodusHistory.getMappedSections(sections);
    this.sections = mappedSections;
  }

  static getMappedSections(sections) {
    const keys = Object.keys(sections);
    return keys.reduce((acc, key) => {
      const section = sections[key];
      const latestPatch = AenderungsmodusHistory.getSectionBaseLatestPatch(section.patches);
      const mappedSection = new AenderungsmodusSection(
        latestPatch.revision,
        latestPatch.subRevision,
        section.patches
      );
      if (!mappedSection.valid) {
        throw new Error('Invalid section');
      }
      acc[key] = mappedSection;
      return acc;
    }, {});
  }

  static getSectionBaseLatestPatch(patches) {
    if (!Array.isArray(patches) || patches.length === 0) {
      return null;
    }
    return patches.toSorted((a, b) => b.revision - a.revision)[0];
  }

  static fromJSON(json) {
    if (!json || typeof json !== 'object') {
      return null;
    }
    if (json instanceof AenderungsmodusHistory) return json;

    return new AenderungsmodusHistory(
      json.order,
      json.sections
    );
  }

  get sectionsAreValid() {
    const keys = Object.keys(this.sections);
    return keys.every((key) => {
      const typeofKey = typeof key;
      if (typeofKey !== 'string') {
        return false;
      }
      const section = this.sections[key];
      return section.patches.every(
        (patch) => patch instanceof AenderungsmodusPatch
          && patch.valid
      );
    });
  }

  get validOrder() {
    return !!this.order && Array.isArray(this.order)
      && this.order.every((element) => typeof element === 'string');
  }

  get valid() {
    return this.validOrder && this.sectionsAreValid;
  }
}
