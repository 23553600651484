import { get, patch, post } from '../axios';
import clientConfig from '../client/config';

const API_PREFIX = `${clientConfig.API_PREFIX}/trackChanges`;

export default {
  /**
   * Aktiviert den Änderungsmodus für ein Dokument
   *
   * @param {String} clientElementId
   * @returns {Promise}
   */
  startRecording(clientElementId) {
    const URL = `${API_PREFIX}/startRecording/${clientElementId}`;

    return post(URL);
  },

  /**
   * Beenden den Änderungsmodus für ein Dokument
   *
   * @param {String} clientElementId
   * @param {AenderungsmodusMode} mode // acceptAll, acceptNone, abort
   * @returns {Promise}
   */
  stopRecording(clientElementId, mode) {
    const URL = `${API_PREFIX}/stopRecording/${clientElementId}/${mode}`;

    return post(URL);
  },

  /**
   * get Änderungshistorie für ein Dokument
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @returns {Promise<AxiosResponse<AenderungsmodusHistory>>}
   */
  getHistory(clientElementId, subElementId) {
    const URL = `${API_PREFIX}/${clientElementId}/${subElementId}`;

    return get(URL)
      .then((response) => {
        const { data } = response;
        return {
          data: {
            order: data.subelementRecord.sections.order,
            sections: data.sections,
            subelementRecord: data.subelementRecord
          },
          status: true
        };
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 400) {
          return { data: { order: [], sections: {} }, status: false };
        }
        throw error;
      });
  },

  /**
   * patch Änderungen für ein Dokument
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @param {String} sectionId
   * @param {String} baseRevision
   * @param {Object} body
   * @param {Boolean} newPatch // optional
   * @returns {Promise<AxiosResponse<{ nextBaseRevision: number}>>}
   */
  patchChanges({
    clientElementId,
    subElementId,
    sectionId,
    baseRevision,
    baseSubRevision,
    body,
    newPatch = false
  }) {
    const newPatchStr = newPatch ? '?newPatch=true' : '';
    const URL = `${API_PREFIX}/${clientElementId}/${subElementId}/${sectionId}/${baseRevision}`
      + `/${baseSubRevision}${newPatchStr}`;

    return patch(URL, body);
  },

  /**
   * post updated order for Änderungsmodus
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @param {Array<String>} order
   * @returns {Promise}
   */
  postOrder(clientElementId, subElementId, order) {
    const URL = `${API_PREFIX}/order/${clientElementId}/${subElementId}`;

    return post(URL, { order });
  },

  /**
   * add comments to patch
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @param {String} sectionId
   * @param {String} revision
   * @param {String} comment
   * @param {String}
   */
  addCommentToPatch(
    clientElementId,
    subElementId,
    sectionId,
    revision,
    comment
  ) {
    const URL = `${API_PREFIX}/comment/${clientElementId}/${subElementId}/${sectionId}/${revision}`;

    return post(URL, { comment });
  },

  /**
   * commit all changes from a section
   *
   * @param {String} clientElementId
   * @param {String} subelementId
   * @param {String} sectionId
   * @param {String} revision
   * @param {String} subRevision
   * @returns {Promise}
   */
  postTrackChangesSubelementSectionCommit(
    clientElementId,
    subelementId,
    sectionId,
    revision,
    subRevision
  ) {
    const URL = `${API_PREFIX}/commit/${clientElementId}/${subelementId}/${sectionId}/${revision}`
      + `/${subRevision}`;

    return post(URL);
  },

  /**
   * deny all changes from a section
   *
   * @param {String} clientElementId
   * @param {String} subelementId
   * @param {String} sectionId
   * @returns {Promise}
   */
  postTrackChangesSubelementSectionDeny(
    clientElementId,
    subelementId,
    sectionId
  ) {
    const URL = `${API_PREFIX}/deny/${clientElementId}/${subelementId}/${sectionId}`;

    return post(URL);
  },

  /**
   * commit all changes from subelement
   *
   * @param {String} clientElementId
   * @param {String} subelementId
   * @returns {Promise}
   */
  postTrackChangesSubelementCommit(
    clientElementId,
    subelementId
  ) {
    const URL = `${API_PREFIX}/commitAll/${clientElementId}/${subelementId}`;

    return post(URL);
  },

  /**
   * deny all changes from subelement
   *
   * @param {String} clientElementId
   * @param {String} subelementId
   * @returns {Promise}
   */
  postTrackChangesSubelementDeny(
    clientElementId,
    subelementId
  ) {
    const URL = `${API_PREFIX}/denyAll/${clientElementId}/${subelementId}`;

    return post(URL);
  },

  /**
   * get Vergleichsversionen for a document by clientElementId
   *
   * @param {String} clientElementId
   * @returns {Promise}
   */
  getVergleichsversionen(clientElementId) {
    const URL = `${API_PREFIX}/${clientElementId}/diff`;

    return get(URL);
  }
};
