import { post } from '../axios';

const REQUEST_MAPPING = '/pdf';

export default {
  /**
   * generate pdf from html and css
   *
   * @param {string} html
   * @param {string} css
   * @param {string} script
   * @param {object} margin
   * @returns {Promise<string>}
   */
  generatePdf(html, css, script, margin) {
    const url = `${REQUEST_MAPPING}/generate`;
    const body = {
      html,
      css,
      script,
      margin
    };
    return new Promise((resolve, reject) => {
      post(url, body)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }
};
